import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false);
  const handleNavClick = () => {
    setOpenNav(false);
  };
  return (
    <div className="navbar">
      <div className="navBack" />
      <GiHamburgerMenu
        className="burgerIcon"
        onClick={() => setOpenNav(!openNav)}
      />
      <div className="navContent">
        <Link to="/" className="navHeading">
          <h1>GUITAR PEDAL SHOOTOUT</h1>
        </Link>
        <Link to="/search" className="navLink">
          <p>Filters</p>
        </Link>
        <Link to="/about" className="navLink">
          <p>About Us</p>
        </Link>
        <Link to="/contact" className="navLink">
          <p>Contact Us</p>
        </Link>
        <Link to="/subscribe" className="navLink">
          <p>Subscribe</p>
        </Link>
      </div>

      <Link onClick={handleNavClick} to="/" className="navHeadingMob">
        <h1>GUITAR PEDAL SHOOTOUT</h1>
      </Link>
      {openNav && (
        <div className="navMobContent">
          <Link onClick={handleNavClick} to="/search" className="navLink">
            <p>Filters</p>
          </Link>
          <Link onClick={handleNavClick} to="/about" className="navLink">
            <p>About Us</p>
          </Link>
          <Link onClick={handleNavClick} to="/contact" className="navLink">
            <p>Contact Us</p>
          </Link>
          <Link onClick={handleNavClick} to="/subscribe" className="navLink">
            <p>Subscribe</p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;
