/* eslint-disable jsx-a11y/alt-text */
import { capitalizeWords } from "../../misc/HelperFunctions";
import { useState } from "react";

export const PedalRow = ({ pedal, onClickImage, allLinks }) => {
  const pedalData = pedal.name.split("_");
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <tr key={pedal.id}>
      <td className="tooltip-parent">
        <img
          className="pedalImage"
          src={pedal.image}
          onClick={() => onClickImage(pedal)}
        />
        {showTooltip && (
          <div className="tooltip">
            {allLinks?.[pedalData[0]]?.details ?? "No details..."}
          </div>
        )}
      </td>
      <td>
        <div className="pedalNameContainer">
          <span
            className="pedalMoreInfo"
            onClick={() =>
              setShowTooltip((prevShowTooltip) => !prevShowTooltip)
            }
          >
            More info...
          </span>
          <div>{capitalizeWords(pedalData[0])}</div>
        </div>
      </td>
      <td>{capitalizeWords(pedalData[1])}</td>
      <td>{capitalizeWords(`${pedalData[2]} ${pedalData[3]}`)}</td>
      <td>{capitalizeWords(pedalData[4])}</td>
    </tr>
  );
};
