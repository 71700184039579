import React, { useEffect, useRef, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import ReactSlider from "react-slider";
import playImg from "../../images/pause.png";
import pauseImg from "../../images/Vector.png";
import "./audio.css";

const MyAudioPlayer = ({
  raw,
  playOrNot,
  setplay,
  hoverData,
  number,
  setPlayTime,
  playTime,
  buyHref,
  hideSlider,
}) => {
  const dashes = [];

  const [isHover, setIsHover] = useState(false);
  const [volume, setInsideVolume] = useState(0.8);
  const [time, setTime] = useState(0);
  const audioRef = useRef();

  const loadDashed = () => {
    for (let i = 0; i < 10; i++) {
      dashes.push(<div className="timelineDash" />);
    }
  };

  const handleClick = () => {
    if (playOrNot) {
      setPlayTime(audioRef.current.currentTime);
      audioRef.current.src = hoverData?.audio;
      setplay(-1);
    } else {
      audioRef.current.currentTime = 0;
      audioRef.current.src = "";
      setplay(number);
    }
  };

  const handleHover = (val) => {
    setIsHover(val);
  };

  const handleChangeVol = (val) => {
    setInsideVolume(val);
    if (playOrNot) {
      audioRef.current.volume = val;
    }
  };

  const playOrPause = () => {
    if (!playOrNot) {
      if (hoverData !== null) {
        audioRef.current.pause();
        audioRef.current.src = "";
      }
    } else {
      if (hoverData !== null) {
        audioRef.current.src = hoverData?.audio;
        audioRef.current.play();
        if (volume === 0) {
          audioRef.current.volume = 0.8;
          setInsideVolume(0.8);
        } else {
          audioRef.current.volume = volume;
        }
      }
    }
  };

  useEffect(() => {
    setTime(playTime);
  }, [playTime]);

  useEffect(() => {
    playOrPause();
  }, [playOrNot]);

  useEffect(() => {
    if (hoverData) {
      if (playOrNot) {
        audioRef.current.play();
      }
    }
  }, [hoverData]);

  return (
    <div id="audio-player-container" className="timeline">
      {loadDashed()}
      {hoverData === null ? null : (
        <audio
          src={hoverData?.audio}
          volume={volume}
          loop={false}
          ref={audioRef}
          onEnded={handleClick}
        />
      )}
      <button
        onMouseOver={() => handleHover(true)}
        onMouseOut={() => handleHover(false)}
        onClick={handleClick}
        className={raw ? "rawBtn" : undefined}
      >
        <div className="timelineBtnContent">
          <img src={playOrNot ? playImg : pauseImg} />
          {raw && <p>Raw</p>}
        </div>
      </button>

      {!hideSlider && (
        <>
          {buyHref ? (
            <a
              className="buy-btn-link"
              href={buyHref}
              target="_blank"
              rel="noreferrer"
            >
              <button className="buy-btn">BUY NOW</button>
            </a>
          ) : (
            <div className="timelineTime">{dashes}</div>
          )}
          <ReactSlider
            value={volume}
            onSliderClick={(newVal, index) => handleChangeVol(newVal)}
            onChange={(newVal, index) => handleChangeVol(newVal)}
            min={0}
            max={1}
            step={0.1}
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
          />
          {buyHref ? (
            <a
              href={buyHref}
              className="mobileBtn"
              target="_blank"
              rel="noreferrer"
            >
              <button className="buy-btn">BUY NOW</button>
            </a>
          ) : (
            <div className="timelineTime mobileBtn">{dashes}</div>
          )}
        </>
      )}
    </div>
  );
};

export default MyAudioPlayer;
