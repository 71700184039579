import React, { useState } from 'react';
import './subscribePage.css';
import { firestore } from '../../firebase';
import { setDoc, doc } from "@firebase/firestore"
import { useNavigate } from 'react-router-dom';

const SubscribePage = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async(e) => {
        e.preventDefault();

        let data = {
            email,
            name
        }
        try {
            const docRef = doc(firestore, 'subscribers', email);

            setDoc(docRef, data).then(() => 
                {
                    alert('Subscribed');
                    navigate('/');
                }
            )
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className='subscribePage'>
            <h1>subscribe</h1>
            <form onSubmit={handleSubmit}>
                <div className='formElement'>
                    <label htmlFor='name'>Name</label>
                    <input onChange={(e)=>setName(e.target.value)} type="text" id="name" required />
                </div>
                <div className='formElement'>
                    <label htmlFor='email'>Email</label>
                    <input onChange={(e)=>setEmail(e.target.value)} type="email" id="email" required />
                </div>
                <button type='submit'><p>Submit</p></button>
            </form>
        </div>
    )
}

export default SubscribePage;