import { doc, getDoc } from "@firebase/firestore";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PhraseSelection from "../../components/PhraseSelection/PhraseSelection";
import RigSelection from "../../components/RigSelection/RigSelection";
import { firestore } from "../../firebase";
import {
  checkPedalsExist,
  checkPedalsExistWithoutPhrase,
  getAllAmplifiers,
  getAllGuitars,
  getAllPedals,
  getAllPhrases,
  loadAllFilteredPhrases,
  loadFilteredPedalsWithoutPhrase,
  loadFilteredPedalsWithPhrase,
  getBuyLink,
} from "../../misc/HelperFunctions";
import MyAudioPlayer from "./Audio";
import "./selectionPage.css";
import NoImage from "../../images/noImage.png";

const Rig = () => {
  const navigate = useNavigate();
  let { state } = useLocation();

  const [playNumber, setPlayNumber] = useState(0); //1
  const [playTime, setPlayTime] = useState(0); //2
  const [renderInfo, setRenderInfo] = useState(false); //3
  const [renderBInfo, setRenderBInfo] = useState(false); //4

  const [pedalA, setPedalA] = useState(null); //5
  const [pedalALinks, setPedalALinks] = useState(null);
  const [pedalB, setPedalB] = useState(null); //6
  const [pedalBLinks, setPedalBLinks] = useState(null);
  const [guitar, setGuitar] = useState(null); //7
  const [amplifier, setAmplifier] = useState(null); //8
  const [phrase, setPhrase] = useState(null); //9

  const [allAmplifier, setAllAmplifier] = useState([]); //10
  const [allGuitar, setAllGuitar] = useState([]); //11
  const [allPhrases, setAllPhrases] = useState([]); //12
  const [filteredPhrases, setFilteredPhrases] = useState([]); //13
  const [filteredPedals, setFilteredPedals] = useState([]); //14
  const [allPedals, setAllPedals] = useState([]); // 15

  const [rawAudio, setRawAudio] = useState(null); //16
  const [pedalAAudio, setPedalAAudio] = useState(null); //17
  const [pedalBAudio, setPedalBAudio] = useState(null); //18

  const [loadFilteredPhrases, setLoadFilteredPhrases] = useState(false);
  const [loadPhrase, setLoadPhrase] = useState(false);
  const [reload, setReload] = useState(false);

  const { manufacturer } = useParams();

  useLayoutEffect(() => {
    getAllGuitars(setAllGuitar, setGuitar).then(() => {
      getAllAmplifiers(setAllAmplifier, setAmplifier, manufacturer).then(() => {
        getAllPhrases(
          setAllPhrases,
          undefined,
          manufacturer ? "ctc" : undefined
        ).then(() => {
          getAllPedals(setAllPedals, manufacturer).then(() => {
            setReload(true);
          });
        });
      });
    });

    if (manufacturer) {
      sessionStorage.setItem("user", "placeholder");
    }
  }, [manufacturer]);

  useEffect(() => {
    if (guitar && amplifier) {
      setFilteredPhrases([]);
      loadAllFilteredPhrases(
        guitar,
        allPhrases,
        amplifier,
        setFilteredPhrases,
        filteredPhrases
      ).then(() => {
        setLoadPhrase(true);
      });
    }
  }, [reload]);

  useEffect(() => {
    if (filteredPhrases) {
      if (!phrase) {
        setPhrase(filteredPhrases[0]);
      }
    }
  }, [loadPhrase, filteredPhrases]);

  useEffect(() => {
    if (guitar && amplifier && phrase) {
      setReload(true);
      let pedals = allPedals;

      loadFilteredPedalsWithPhrase(pedals, guitar, amplifier, phrase).then(
        (resP) => {
          if (resP.length > 0) {
            setFilteredPedals(resP);
            checkPedalsExist(filteredPedals, pedalA, pedalB).then((resA) => {
              if (resA.checkA !== null && resA.checkB !== null) {
                if (state?.slot === "A") {
                  setPedalA(state.pedal);
                } else if (!state || state.slot !== "A") {
                  setPedalA(resA.checkA);
                }
                if (state?.slot === "B") {
                  setPedalB(state.pedal);
                } else if (!state || state.slot !== "B") {
                  setPedalB(resA.checkB);
                }
              } else {
                loadFilteredPedalsWithoutPhrase(
                  filteredPedals,
                  guitar,
                  amplifier,
                  pedalA,
                  pedalB
                ).then((resPA) => {
                  checkPedalsExistWithoutPhrase(resPA, pedalA, pedalB).then(
                    (resB) => {
                      if (resB && resB[0] !== null && resB[1] !== null) {
                        if (state?.slot === "A") {
                          setPedalA(state.pedal);
                        } else if (!state || state.slot !== "A") {
                          setPedalA(resB[0]);
                        }
                        if (state?.slot === "B") {
                          setPedalB(state.pedal);
                        } else if (!state || state.slot !== "B") {
                          setPedalB(resB[1]);
                        }
                        filteredPhrases.map((phraseT) => {
                          if (
                            phraseT.name.toUpperCase() ===
                            pedalA.name.split("_")[1]
                          ) {
                            setPhrase(phraseT);
                          }
                        });
                      } else {
                        setToDefaultPedals();
                      }
                    }
                  );
                });
              }

              if (state) {
                const pedalData = state.pedal.name.split("_");
                const phrase = allPhrases.find(
                  (phrase) => phrase.type === pedalData[1]
                );
                const guitar = allGuitar.find(
                  (guitar) => guitar.type === `${pedalData[2]}_${pedalData[3]}`
                );
                const amp = allAmplifier.find(
                  (amp) => amp.type === pedalData[4]
                );
                setPhrase(phrase);
                setGuitar(guitar);
                setAmplifier(amp);

                setTimeout(() => {
                  navigate("/");
                }, 200);
              }
            });
          }
        }
      );
    }
  }, [guitar, amplifier, phrase, filteredPhrases, pedalA, pedalB]);

  useEffect(() => {
    if (filteredPedals) {
      if (!pedalA && !pedalB) {
        setToDefaultPedals();
      }
    }
  }, [filteredPedals]);

  const setToDefaultPedals = () => {
    setPedalA(filteredPedals[0]);
    setPedalB(filteredPedals[1] ?? filteredPedals[0]);
  };

  const getAudioA = async () => {
    let pedalName = pedalA.name.substring(0, pedalA.name.length - 1);
    const pathA = pedalName + "A";
    const docRefA = doc(firestore, "audio", pathA);
    const docSnapA = await getDoc(docRefA);
    if (docSnapA.data() !== undefined) {
      const objA = {
        name: pathA,
        audio: docSnapA.data().audio,
      };
      setPedalAAudio(objA);
    } else {
      setPedalAAudio(null);
    }
  };
  const getAudioB = async () => {
    let pedalName = pedalB.name.substring(0, pedalB.name.length - 1);
    const pathB = pedalName + "A";
    const docRefB = doc(firestore, "audio", pathB);
    const docSnapB = await getDoc(docRefB);
    if (docSnapB.data() !== undefined) {
      const objB = {
        name: pathB,
        audio: docSnapB.data().audio,
      };
      setPedalBAudio(objB);
    } else {
      setPedalBAudio(null);
    }
  };
  const getAudioRaw = async () => {
    const pathRaw =
      "RAW_" +
      phrase.name.toUpperCase() +
      "_" +
      guitar.type.toUpperCase() +
      "_" +
      amplifier.type.toUpperCase() +
      "_A";
    const docRefRaw = doc(firestore, "audio", pathRaw);
    const docSnapRaw = await getDoc(docRefRaw);
    if (docSnapRaw.data() !== undefined) {
      const objRaw = {
        name: pathRaw,
        audio: docSnapRaw.data().audio,
      };
      setRawAudio(objRaw);
    } else {
      setRawAudio(null);
    }
  };

  useEffect(() => {
    if (
      guitar !== undefined &&
      amplifier !== undefined &&
      phrase !== undefined &&
      guitar !== null &&
      amplifier !== null &&
      phrase !== null
    ) {
      getAudioRaw();
    } else {
      setRawAudio(null);
    }
    if (
      pedalA !== undefined &&
      guitar !== undefined &&
      amplifier !== undefined &&
      phrase !== undefined &&
      pedalA !== null &&
      guitar !== null &&
      amplifier !== null &&
      phrase !== null
    ) {
      getAudioA();
    } else {
      setPedalAAudio(null);
    }
    if (
      pedalB !== undefined &&
      guitar !== undefined &&
      amplifier !== undefined &&
      phrase !== undefined &&
      pedalB !== null &&
      guitar !== null &&
      amplifier !== null &&
      phrase !== null
    ) {
      getAudioB();
    } else {
      setPedalBAudio(null);
    }
  }, [guitar, pedalA, amplifier, pedalB]);

  useEffect(() => {
    if (guitar) {
      setPedalA(null);
      setPedalB(null);
      setPhrase(null);
      getAllPhrases(setAllPhrases, undefined, manufacturer ? "ctc" : undefined);
    }
  }, [guitar]);

  useEffect(() => {
    pedalA && getBuyLink(pedalA.name).then(setPedalALinks);
    pedalB && getBuyLink(pedalB.name).then(setPedalBLinks);
  }, [pedalA, pedalB]);

  return (
    <div className="rigPage">
      <div className="container selectionPage">
        {/* Rig & Phrase Selection */}
        <div className="rigSelection">
          <div>
            <h1>Select Rig</h1>
            <div className="rigs">
              <RigSelection
                data={allGuitar}
                value={guitar}
                heading={"guitar/pickup"}
                selector={setGuitar}
                phraseSelector={setPhrase}
                setReload={setReload}
                reload={reload}
              />
              <RigSelection
                data={allAmplifier}
                value={amplifier}
                heading={"amplifier"}
                selector={setAmplifier}
                phraseSelector={setPhrase}
                setReload={setReload}
                reload={reload}
              />
            </div>
          </div>
          <div className="selectPhrase">
            <h1>Select Phrase</h1>
            <PhraseSelection
              isDisabled={!guitar || !amplifier}
              type={"Phrase"}
              extraCheck={rawAudio}
              value={phrase}
              selector={setPhrase}
              data={filteredPhrases}
              setReload={setReload}
              reload={reload}
            />
          </div>
          <div className="timeline">
            <MyAudioPlayer
              raw={true}
              playOrNot={playNumber === 1 ? true : false}
              hoverData={rawAudio || null}
              setplay={setPlayNumber}
              number={1}
              setPlayTime={setPlayTime}
              playTime={playTime}
            />
          </div>
        </div>
        {/* Pedal Selection */}
        <div className="pedalSelection">
          <div className="pedalDiv">
            <div>
              <h1>Select Pedal - A</h1>
              <PhraseSelection
                isDisabled={!phrase}
                type={"Pedal - A"}
                value={pedalA}
                selector={setPedalA}
                data={filteredPedals.length > 0 ? filteredPedals : allPedals}
              />
              {renderInfo && pedalA !== undefined && (
                <div className="pedalDetails">
                  <div
                    className="pedalDetailsCross"
                    onClick={() => setRenderInfo(false)}
                  >
                    <p>&times;</p>
                  </div>
                  <p className="pedalName">{pedalA?.name}</p>
                  <br />
                  <p>{pedalALinks?.details || "No Details Available"}</p>
                </div>
              )}
              <div className="pedalDivImg">
                {pedalA && (
                  <div>
                    <img src={pedalA?.image ? pedalA.image : NoImage} />
                    <div className="pedalLinksContainer">
                      <div
                        className="pedalInfo"
                        onClick={() => setRenderInfo((prevInfo) => !prevInfo)}
                      >
                        i
                      </div>
                      {pedalALinks?.youtube ? (
                        <a
                          className="pedalInfo"
                          href={pedalALinks?.youtube}
                          target="_blank"
                          rel="noreferrer"
                        >
                          v
                        </a>
                      ) : (
                        <div
                          className="pedalInfo"
                          style={{ cursor: "not-allowed" }}
                        >
                          v
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="timeline">
              <MyAudioPlayer
                raw={false}
                playOrNot={playNumber === 2 ? true : false}
                hoverData={pedalAAudio}
                setplay={setPlayNumber}
                number={2}
                setPlayTime={setPlayTime}
                playTime={playTime}
                buyHref={pedalALinks?.manufacturerPage}
              />
            </div>
          </div>
          <div className="pedalDiv">
            <div>
              <h1>Select Pedal - B</h1>
              <PhraseSelection
                isDisabled={!phrase}
                type={"Pedal - B"}
                value={pedalB}
                selector={setPedalB}
                data={filteredPedals.length > 0 ? filteredPedals : allPedals}
              />
              {renderBInfo &&
                pedalB !== undefined &&
                pedalB?.details !== undefined && (
                  <div className="pedalDetails">
                    <div
                      className="pedalDetailsCross"
                      onClick={() => setRenderBInfo((prevInfo) => !prevInfo)}
                    >
                      <p>X</p>
                    </div>
                    <p className="pedalName">{pedalB?.name}</p>
                    <br />
                    <p>{pedalBLinks.details || "No Details Available"}</p>
                  </div>
                )}
              <div className="pedalDivImg">
                {pedalB && (
                  <div>
                    <img src={pedalB?.image ? pedalB.image : NoImage} />
                    <div className="pedalLinksContainer">
                      <div
                        className="pedalInfo"
                        onClick={() => setRenderBInfo((prevInfo) => !prevInfo)}
                      >
                        i
                      </div>
                      {pedalBLinks?.youtube ? (
                        <a
                          className="pedalInfo"
                          href={pedalBLinks?.youtube}
                          target="_blank"
                          rel="noreferrer"
                        >
                          v
                        </a>
                      ) : (
                        <div
                          className="pedalInfo"
                          style={{ cursor: "not-allowed" }}
                        >
                          v
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="timeline">
              <MyAudioPlayer
                raw={false}
                playOrNot={playNumber === 3 ? true : false}
                hoverData={pedalBAudio}
                setplay={setPlayNumber}
                number={3}
                setPlayTime={setPlayTime}
                playTime={playTime}
                buyHref={pedalBLinks?.manufacturerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rig;
