import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API,
  authDomain: "nightshift-f5c51.firebaseapp.com",
  projectId: "nightshift-f5c51",
  storageBucket: "nightshift-f5c51.appspot.com",
  messagingSenderId: "822659705860",
  appId: process.env.FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
