import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({children}) => {
    const [user, setUser] = useState(sessionStorage.getItem('user'));
    
    return (
        <div className='protectedRotue'>
            {
                user && 
                user !== '' ?
                children :
                <Navigate to={'/signin'} replace />
            }
        </div>
    )
}

export default ProtectedRoute