import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import AboutPage from "./pages/AboutPage/AboutPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import FilteringPage from "./pages/FilteringPage";
import CtcPage from "./pages/Manufacturers/Ctc";
import SelectionPage from "./pages/SelectionPage/SelectionPage";
import SignIn from "./pages/SignIn/SignIn";
import SubscribePage from "./pages/SubscribePage/SubscribePage";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  return (
    <div className="App">
      <div className="background">
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <SelectionPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact"
              element={
                <ProtectedRoute>
                  <ContactPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscribe"
              element={
                <ProtectedRoute>
                  <SubscribePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/about"
              element={
                <ProtectedRoute>
                  <AboutPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search"
              element={
                <ProtectedRoute>
                  <FilteringPage />
                </ProtectedRoute>
              }
            />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/manufacturer/ctc" element={<CtcPage />} />
            <Route
              path="/manufacturer/:manufacturer"
              element={<SelectionPage />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
