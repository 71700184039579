import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { logDOM } from "@testing-library/react";
import { firestore } from "../firebase";

export const getAllGuitars = async (setAllGuitar, setGuitar, filter) => {
  const refGuitars = collection(firestore, "guitars");
  return getDocs(refGuitars).then((res) => {
    let data = [];
    res.forEach((doc) => {
      let obj = {
        name: doc.data().name,
        type: doc.data().type,
        image: doc.data().image,
        id: doc.data().id,
        details: doc.data().details,
        manufacturer: doc.data().manufacturer,
      };
      if (
        !filter ||
        (filter &&
          obj.manufacturer?.toLowerCase().includes(filter.toLowerCase()))
      ) {
        data.push(obj);
      }
    });
    data = data.sort((a, b) => (a.id > b.id ? 1 : -1));
    setAllGuitar(data);
    setGuitar(data[0]);
    return data;
  });
};

export const getAllAmplifiers = async (
  setAllAmplifier,
  setAmplifier,
  filter
) => {
  const refAmp = collection(firestore, "amplifiers");
  return getDocs(refAmp).then((res) => {
    let data = [];
    res.forEach((doc) => {
      let obj = {
        name: doc.data().name,
        type: doc.data().type,
        image: doc.data().image,
        id: doc.data().id,
        details: doc.data().details,
        manufacturer: doc.data().manufacturer,
      };
      if (
        !filter ||
        (filter &&
          obj.manufacturer?.find((ampMan) =>
            ampMan.toLowerCase().includes(filter.toLowerCase())
          ))
      ) {
        data.push(obj);
      }
    });
    data = data.sort((a, b) => (a.id > b.id ? 1 : -1));
    setAllAmplifier(data);
    setAmplifier(data[0]);
    return data;
  });
};

export const getAllPhrases = async (setAllPhrases, filter, excludeFilter) => {
  const ref = collection(firestore, "phrases");
  return getDocs(ref).then((res) => {
    let data = [];
    res.forEach((doc) => {
      let obj = {
        id: doc.id,
        orderId: doc.data().id,
        name: doc.data().name,
        type: doc.data().type,
      };
      if (doc.data().manufacturer) {
        obj.manufacturer = doc.data().manufacturer;
      }
      if (
        excludeFilter &&
        doc.data().manufacturer &&
        doc
          .data()
          .manufacturer.toLowerCase()
          .includes(excludeFilter.toLowerCase())
      ) {
        return;
      }
      if (
        !filter ||
        doc.data().manufacturer?.toLowerCase().includes(filter.toLowerCase())
      ) {
        data.push({
          ...obj,
          name:
            !filter && obj.manufacturer
              ? obj.name + " " + obj.manufacturer
              : obj.name,
        });
      }
    });
    setAllPhrases(data);
    return data;
  });
};

export const getAllPedals = async (setAllPedals, manufacturerFilter) => {
  const filteredPedals = [];

  const pedalInfoRef = collection(firestore, "links");
  let pedalInfo = null;
  if (manufacturerFilter) {
    const pedalInfoQuery = query(
      pedalInfoRef,
      where("manufacturer", "==", manufacturerFilter)
    );
    pedalInfo = await getDocs(pedalInfoQuery);
  } else {
    pedalInfo = await getDocs(pedalInfoRef);
  }

  let pedalOrderMap = {};
  pedalInfo.forEach((pedal) => {
    const manufacturer = pedal.data().manufacturer;

    if (manufacturerFilter && manufacturer) {
      filteredPedals.push(pedal.id.toLowerCase());
    }

    pedalOrderMap[pedal.id] = Number(pedal.data().order) ?? 100;
  });

  const refPedals = collection(firestore, "pedals");
  return getDocs(refPedals).then((res) => {
    let data = [];
    res.forEach((doc) => {
      if (
        filteredPedals.length !== 0 &&
        !filteredPedals.some((pedalName) =>
          doc.data().name.toLowerCase().includes(pedalName)
        )
      ) {
        return;
      }
      let obj = {
        id: doc.id,
        name: doc.data().name,
        image: doc.data().image,
        details: doc.data().details,
      };
      data.push(obj);
    });
    data.sort((a, b) => {
      const pedalNameA = a.name.split("_")[0];
      const pedalNameB = b.name.split("_")[0];

      return pedalOrderMap[pedalNameA] - pedalOrderMap[pedalNameB];
    });
    setAllPedals(data);
    return data;
  });
};

export const getAllLinks = async () => {
  const ref = collection(firestore, "links");

  const docs = await getDocs(ref);

  const links = {};

  docs.forEach((link) => (links[link.id] = link.data()));

  return links;
};

export const loadAllFilteredPhrases = async (
  guitar,
  allPhrases,
  amplifier,
  setFilteredPhrases,
  filteredPhrases,
  manufacturerFilter
) => {
  try {
    var filteredPhrases = await allPhrases.map((val) => {
      let stringKey = `RAW_${
        val.name.toUpperCase() +
        (manufacturerFilter ? ` ${manufacturerFilter.toUpperCase()}` : "")
      }_${guitar.type.toUpperCase()}_${amplifier.type.toUpperCase()}_A`;
      loadFilteredPhrases(val, stringKey, setFilteredPhrases, filteredPhrases);
    });
    await filteredPhrases.sort((a, b) => (a.orderId > b.orderId ? 1 : -1));
    return filteredPhrases;
  } catch (error) {
    console.log("error:", error.message);
  }
};

const loadFilteredPhrases = async (
  val,
  stringKey,
  setFilteredPhrases,
  filteredPhrases
) => {
  try {
    const docRefRaw = doc(firestore, "audio", stringKey);
    const docSnapRaw = await getDoc(docRefRaw);
    if (docSnapRaw.data() !== undefined) {
      const prevState = filteredPhrases;
      prevState.push(val);
      prevState.sort((a, b) => a.orderId - b.orderId);
      await setFilteredPhrases(prevState);
    }
  } catch (error) {
    console.log("error:", error.message);
  }
};

export const loadFilteredPedalsWithPhrase = async (
  pedals,
  guitar,
  amplifier,
  phrase
) => {
  let guitarArray = guitar.type.split("_");
  let filteredPedalsT = pedals.filter((val) => {
    let dataArray = val.name.split("_");
    if (
      dataArray[1] === phrase.type.toUpperCase() &&
      dataArray[2] === guitarArray[0].toUpperCase() &&
      dataArray[3] === guitarArray[1].toUpperCase() &&
      dataArray[4] === amplifier.type.toUpperCase()
    ) {
      return dataArray[0];
    }
  });
  return filteredPedalsT;
};

export const checkPedalsExist = async (filteredPedals, pedalA, pedalB) => {
  try {
    let checkA = null;
    let checkB = null;
    filteredPedals.map((fPedal, index) => {
      if (fPedal.name.split("_")[0] === pedalA?.name.split("_")[0]) {
        checkA = fPedal;
      }
      if (fPedal.name.split("_")[0] === pedalB?.name.split("_")[0]) {
        checkB = fPedal;
      }
    });
    return { checkA, checkB };
  } catch (error) {
    console.log(error.message);
  }
};

export const checkPedalsExistWithoutPhrase = async (
  filteredPedals,
  pedalA,
  pedalB
) => {
  const data = await filteredPedals.map((item, index) => {
    if (item.name.split("_")[0] === pedalA.name.split("_")[0]) {
      return item;
    }
  });
  let indexB = null;
  const dataB = await filteredPedals.map((item, index) => {
    let phrase = item.name.split("_")[1];
    data.map((itemA, indexA) => {
      if (!indexB) {
        if (
          itemA &&
          itemA.name.split("_")[1] === phrase &&
          item.name.split("_")[0] === pedalB.name.split("_")[0]
        ) {
          indexB = [itemA, item];
          return;
        }
      }
    });
  });
  return indexB;
};

export const loadFilteredPedalsWithoutPhrase = async (
  pedals,
  guitar,
  amplifier,
  pedalA,
  pedalB
) => {
  try {
    let pedalNameA = pedalA.name.split("_");
    let pedalNameB = pedalB.name.split("_");
    let guitarArray = guitar.type.split("_");
    let filteredPedalsB = pedals.filter((val) => {
      let dataArray = val.name.split("_");
      if (
        (dataArray[0] === pedalNameA[0] || dataArray[0] === pedalNameB[0]) &&
        dataArray[2] === guitarArray[0].toUpperCase() &&
        dataArray[3] === guitarArray[1].toUpperCase() &&
        dataArray[4] === amplifier.type.toUpperCase()
      ) {
        return true;
      }
    });
    return filteredPedalsB;
  } catch (error) {
    console.log(error.message);
  }
};

export const filterPedalsByAttributes = (
  allPedals,
  pedalName,
  amplifierName,
  guitarName,
  phraseName,
  searchTerm
) => {
  // If no filters are applied, return the original array
  let filteredPedals = allPedals.filter((pedal) => {
    const attributes = pedal.name.split("_");

    // Check each parameter before comparing to the corresponding attribute
    const pedalNameMatch =
      !pedalName || attributes[0].toLowerCase() === pedalName.toLowerCase();
    const phraseNameMatch =
      !phraseName || attributes[1].toLowerCase() === phraseName.toLowerCase();
    const guitarNameMatch =
      !guitarName ||
      `${attributes[2]} ${attributes[3]}`.toLowerCase() ===
        guitarName.toLowerCase();
    const amplifierNameMatch =
      !amplifierName ||
      attributes[4].toLowerCase() === amplifierName.toLowerCase();

    // Include the pedal in the filtered array if all matches are true
    return (
      pedalNameMatch && phraseNameMatch && guitarNameMatch && amplifierNameMatch
    );
  });
  // filter searchTerm in filtered pedals
  filteredPedals = filteredPedals.filter((pedal) => {
    return (
      !searchTerm ||
      pedal.name
        .replaceAll("_", " ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  });
  return filteredPedals;
};

export const getUniquePedalNames = (allPedals) => {
  const pedalNames = allPedals.map((pedal) => pedal.name.split("_")[0]);
  return [...new Set(pedalNames)];
};

export const capitalizeWords = (str) => {
  return str.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
};

export const getBuyLink = async (pedalName) => {
  if (!pedalName || typeof pedalName !== "string") {
    return;
  }

  const pedal = pedalName.split("_")[0];

  const pedalRef = doc(firestore, "links", pedal);
  const links = await getDoc(pedalRef);

  return links.data() ?? null;
};
