import React, { useState } from "react";
import "./rigSelection.css";

const RigSelection = ({
  data,
  value,
  heading,
  selector,
  setReload,
  reload,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const onClickOpenModal = (val) => {
    selector(val);
    setOpenModal(false);
    setReload(!reload);
  };

  return (
    <div className="rigselection">
      <p>{heading}</p>
      <button className="rigButton" onClick={() => setOpenModal(!openModal)}>
        <div className="clickableImages">
          {value?.image && (
            <img className="clickableImages" src={value.image} />
          )}
        </div>
      </button>
      <button
        className="rigInfo"
        onClick={() => setIsInfoModalOpen((prevOpen) => !prevOpen)}
      >
        i
      </button>
      {isInfoModalOpen && value?.details && (
        <div className="pedalDetails rigDetails">
          <div
            className="pedalDetailsCross"
            onClick={() => setIsInfoModalOpen(false)}
          >
            <p className="rigDetailsText rigDetailsCross">&times;</p>
          </div>
          <p className="pedalName rigDetailsText">{value.name}</p>
          <br />
          <p className="rigDetailsText">
            {value.details ?? "No Details Available"}
          </p>
        </div>
      )}
      {openModal && <div className="modalBg" />}
      {openModal && (
        <div className="phraseModal">
          <div className="modalHeading">
            <p className="headingPTag">Select {heading}</p>
            <p className="closeModal" onClick={() => setOpenModal(false)}>
              &#215;
            </p>
          </div>
          <div className="mappedPedals">
            {data.map((val) => (
              <div
                onClick={() => onClickOpenModal(val)}
                className="singlePedal singleRig"
              >
                <p>{val.name}</p>
                {val?.image && <img src={val.image} />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RigSelection;
