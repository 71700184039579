import React, { useState } from "react";
import "./phraseSelection.css";

const PhraseSelection = ({
  type,
  value,
  selector,
  data,
  extraCheck,
  setReload,
  reload,
  isDisabled = false,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClick = (val) => {
    selector(val);
    setOpenModal(false);
    if (type === "Phrase") {
      setReload(!reload);
    }
  };

  let pedalName =
    type !== "Phrase" && value?.name.replace(/stardust/gi, "").split("_");

  return (
    <div className="phraseDiv">
      <button
        disabled={isDisabled}
        onClick={() => setOpenModal(!openModal)}
        className="phraseselection"
      >
        {type === "Phrase" && (
          <span style={{ color: "#858585", fontSize: "12px" }}>PHRASE</span>
        )}
        <p>
          {type === "Phrase"
            ? extraCheck && value?.name
            : pedalName !== undefined && pedalName[0].trimStart()}
        </p>
      </button>
      {openModal && <div className="modalBg" />}
      {openModal && (
        <div className={type === "Phrase" ? "phraseModal" : "phraseModalPedal"}>
          <div className="modalHeading">
            <p className="headingPTag">Select {type}</p>
            <p className="closeModal" onClick={() => setOpenModal(false)}>
              &#215;
            </p>
          </div>
          {type === "Phrase" && (
            <div>
              {data.length > 0 ? (
                <ul>
                  {data.map((val) => (
                    <li onClick={() => handleClick(val)}>{val.name}</li>
                  ))}
                </ul>
              ) : (
                <p>No Phrases Found</p>
              )}
            </div>
          )}
          {type !== "Phrase" && (
            <div className="mappedPedals">
              {data.map((val) => (
                <div
                  onClick={() => handleClick(val)}
                  className="singlePedal singlePhrase"
                >
                  {val?.image && <img src={val.image} />}
                  {val.name.replace(/stardust/gi, "").split("_")[0] && (
                    <p
                      style={{
                        margin: "0",
                        fontSize: "0.8rem",
                      }}
                    >
                      {val.name
                        .replace(/stardust/gi, "")
                        .split("_")[0]
                        .trimStart()}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PhraseSelection;
