import React from "react";
import "./aboutPage.css";

const AboutPage = () => {
  return (
    <div className="aboutPage">
      <div className="header">
        <h1>About Us</h1>
        <p>
          Jonathan Grossman is a Los Angeles based gear nerd, guitar player,
          songwriter and audio mixer. He owns the post audio house{" "}
          <a
            target="_blank"
            href="https://www.NightShiftAudio.com"
            rel="noreferrer"
          >
            NightShiftAudio.com
          </a>
          . His curiosity about the zillions of guitar pedals led to creating
          this app. In contrast to the many shootouts on YouTube, this app gets
          to the point. No talking, no BS.
        </p>
        <h3>Identical phrases</h3>
        <h3>Identical recording chain</h3>
        <h3>The only difference is the pedal</h3>
        <p>
          All performances are played through the real pedals. Nothing has been
          modeled.
        </p>
        <div className="categorySection">
          <h3>GUITARS</h3>
          <div className="categorySubType">
            <p>Telecaster Thinline - custom made to 69 specs</p>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.ebay.com/str/supratonec"
                  rel="noreferrer"
                >
                  Supratone Swamp
                </a>{" "}
                ash body
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://usacustomguitars.com/"
                  rel="noreferrer"
                >
                  USA CUSTOM
                </a>{" "}
                maple neck with rosewood fretboard{" "}
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.mojotone.com/52-Quiet-Coil-Tele-Pickup"
                  rel="noreferrer"
                >
                  Mojotone 52 Quiet Coil
                </a>{" "}
                telecaster pickups
              </li>
            </ul>
          </div>
          <div className="categorySubType">
            <p>
              1998 Gibson Les Paul studio - stripped and refinished with clear
              nitro
            </p>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.seymourduncan.com/single-product/antiquity-humbucker"
                  rel="noreferrer"
                >
                  Seymour Duncan Antiquities pickups
                </a>
              </li>
              <li>
                All hardware/electronics replaced to 59 spec per Dave Stephens’s
                recs
              </li>
            </ul>
          </div>
        </div>
        <div className="categorySection">
          <h3>AMPS</h3>
          <div className="categorySubType">
            <ul>
              <li>Fender Bandmaster 1965 Blackface 40 watt head</li>
              <li>Vox AC30TB 2004 30 watt head (made in England)</li>
              <li>
                <a
                  target="_blank"
                  href="https://www.mojotone.com/British-Style-45W-Amplifier-Kit"
                  rel="noreferrer"
                >
                  Mojotone JTM45
                </a>{" "}
                style 45 watt head
              </li>
            </ul>
          </div>
        </div>

        <div className="categorySectionGlobal">
          <div className="categorySubType">
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.uaudio.com/hardware/ox.html"
                  rel="noreferrer"
                >
                  Universal Audio Ox Box
                </a>{" "}
                - 1x12 speaker with plate reverb
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.ehx.com/products/1440/"
                  rel="noreferrer"
                >
                  EHX 1440 Looper
                </a>
              </li>
              <li>Mogami cables with Neutrik connectors.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
