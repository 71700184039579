import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PedalRow } from "../../components/Filtering/PedalRow";
import {
  filterPedalsByAttributes,
  getUniquePedalNames,
  getAllGuitars,
  getAllPedals,
  getAllPhrases,
  capitalizeWords,
  getAllLinks,
} from "../../misc/HelperFunctions";
import "./styles.css";

export default function FilteringPage() {
  const [allGuitar, setAllGuitar] = useState([]);
  const [allPhrases, setAllPhrases] = useState([]);
  const [allPedals, setAllPedals] = useState([]);
  const [allLinks, setAllLinks] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedPedal, setSelectedPedal] = useState("");
  const [selectedAmplifier, setSelectedAmplifier] = useState("");
  const [selectedGuitar, setSelectedGuitar] = useState("");
  const [selectedPhrase, setSelectedPhrase] = useState("");
  const [filtered, setFiltered] = useState([]);

  const [selectedPedalForModal, setSelectedPedalForModal] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getAllGuitars(setAllGuitar, () => {}).then(() => {
      getAllPhrases(setAllPhrases).then(() => {
        getAllPedals(setAllPedals).then((data) => {
          setFiltered(data);
          setIsLoading(false);
        });
      });
    });

    getAllLinks().then(setAllLinks);
  }, []);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPedal,
    selectedAmplifier,
    selectedGuitar,
    selectedPhrase,
    searchTerm,
  ]);

  const filterData = () => {
    setIsLoading(true);
    const filtered = filterPedalsByAttributes(
      allPedals,
      selectedPedal.trim(),
      selectedAmplifier.trim(),
      selectedGuitar.trim(),
      selectedPhrase.trim(),
      searchTerm.trim()
    );
    setFiltered(filtered);
    setIsLoading(false);
  };

  const handleClickPedalImage = (pedal) => {
    setSelectedPedalForModal(pedal);
  };

  const pedalRows = filtered.map((pedal) => (
    <PedalRow
      allLinks={allLinks}
      pedal={pedal}
      onClickImage={handleClickPedalImage}
    />
  ));

  const navigate = useNavigate();

  const handleClickLoadPedal = (slot) => {
    navigate("/", { state: { pedal: selectedPedalForModal, slot } });
  };

  return (
    <>
      {selectedPedalForModal && (
        <>
          <div className="phraseModal">
            <h3>{selectedPedalForModal.name.split("_")[0]}</h3>
            <p style={{ color: "white" }}>
              Choose which slot you want to load the pedal into
            </p>
            <div className="signinBtnDiv">
              <button
                className="signinBtn outline"
                style={{ marginRight: "1.5rem" }}
                onClick={() => setSelectedPedalForModal(null)}
              >
                <p>Cancel</p>
              </button>
              <button
                className="signinBtn"
                onClick={() => handleClickLoadPedal("A")}
              >
                <p>Pedal A</p>
              </button>
              <button
                className="signinBtn"
                onClick={() => handleClickLoadPedal("B")}
              >
                <p>Pedal B</p>
              </button>
            </div>
          </div>
          <div className="modalBg" />
        </>
      )}
      <div className="FilteringPage">
        <div className="FilteringPage__search">
          <input
            className="searchField"
            disabled={isLoading}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Anything..."
          />
          <select
            className="selectDropDown"
            disabled={isLoading}
            value={selectedGuitar}
            onChange={(e) => setSelectedGuitar(e.target.value)}
          >
            <option value="">All Guitars</option>
            {allGuitar.map((guitar, index) => (
              <option key={index} value={guitar.name.replace("PICKUP", "")}>
                {capitalizeWords(guitar.name)}
              </option>
            ))}
          </select>
          <select
            className="selectDropDown"
            disabled={isLoading}
            value={selectedAmplifier}
            onChange={(e) => setSelectedAmplifier(e.target.value)}
          >
            <option value="">All Amplifiers</option>
            <option value="Fender">65 Fender BandMaster</option>
            <option value="Marshall">MojoTome JTM45</option>
            <option value="Vox">Vox AC30</option>
          </select>
          <select
            className="selectDropDown"
            disabled={isLoading}
            value={selectedPedal}
            onChange={(e) => setSelectedPedal(e.target.value)}
          >
            <option value="">All Pedals</option>
            {getUniquePedalNames(allPedals)?.map((pedal, index) => (
              <option key={index} value={pedal}>
                {capitalizeWords(pedal)}
              </option>
            ))}
          </select>
          <select
            className="selectDropDown"
            disabled={isLoading}
            value={selectedPhrase}
            onChange={(e) => setSelectedPhrase(e.target.value)}
          >
            <option value="">All Phrases</option>
            {allPhrases.map((phrase, index) => (
              <option key={index} value={phrase.name}>
                {capitalizeWords(phrase.name)}
              </option>
            ))}
          </select>
        </div>
        <div className="Info">{filtered.length} results found</div>
        <div className="Filtered__table">
          {filtered.length === 0 ? (
            <div className="Filtered__table__empty">No results found</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Pedal</th>
                  <th>Phrase</th>
                  <th>Guitar</th>
                  <th>Amplifier</th>
                </tr>
              </thead>
              <tbody>{pedalRows}</tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
