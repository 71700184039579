import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './contactPage.css';

const ContactPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async(e) => {
        e.preventDefault();
        if(name === '' || email === '' || message === '') {
          return;
        }

        let details = {
          name,
          email,
          message
        };
        let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(details),
        });
        let result = await response.json();
        alert(result.status);

        if(response.ok) {
          navigate("/")
        }
    }
    return (
        <div className='contactPage'>
            <h1>Contact Us</h1>
            <form onSubmit={(e)=>handleSubmit(e)}>
                <div className='formElement'>
                    <label htmlFor='name'>Name</label>
                    <input id="name" type="text" required onChange={(e)=>setName(e.target.value)}/>
                </div>
                <div className='formElement'>
                    <label htmlFor='email'>Email</label>
                    <input id="email" type="email" required onChange={(e)=>setEmail(e.target.value)}/>
                </div>
                <div className='formElement'>
                    <label htmlFor='message'>Message</label>
                    <textarea rows="8" id="message" type="text" required onChange={(e)=>setMessage(e.target.value)}/>
                </div>
                <button type='submit'><p>Submit</p></button>
            </form>
        </div>
    )
}

export default ContactPage