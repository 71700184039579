import React, { useState } from "react";
import { firestore } from "../../firebase";
import { getDoc, doc, setDoc } from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./SignIn.css";
import SplashGraphic from "../../images/Splash_graphic.png";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email === "") {
      return;
    }

    const docRefA = doc(firestore, "subscribers", email);
    const docSnapA = await getDoc(docRefA);

    if (docSnapA.exists()) {
      console.log(email);
      signinUser();
    } else {
      setModalOpen(true);
    }
  };

  const signinUser = () => {
    sessionStorage.setItem("user", email);
    navigate("/");
  };

  const createSubscription = async () => {
    const docRefA = doc(firestore, "subscribers", email);
    await setDoc(docRefA, { email });
    signinUser();
  };
  const handleClickCancelModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="background-image-container"></div>
      {modalOpen && (
        <>
          <div className="modalBg" />
          <div className="phraseModal">
            <p style={{ color: "white" }}>
              By entering your email, you accept the terms of use of this site
              which includes being added to our mailing list for app specific
              updates. Your email will NOT be added to any outside marketing
              lists. We hate SPAM too, so don't worry.
            </p>
            <div className="signinBtnDiv">
              <button
                className="signinBtn outline"
                onClick={handleClickCancelModal}
              >
                <p>Cancel</p>
              </button>
              <button className="signinBtn" onClick={createSubscription}>
                <p>Confirm</p>
              </button>
            </div>
          </div>
        </>
      )}

      <form className="signin" onSubmit={handleLogin}>
        <div>
          <img src={SplashGraphic} className="signinSplash" />
        </div>

        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Email"
        />
        <button>
          <p>Submit</p>
        </button>
      </form>
    </>
  );
};

export default SignIn;
